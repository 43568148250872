import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop } from '../../containers'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import Sidebar from '../../components/Sidebar'

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 12px 12px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }

  &.no-border {
    border: none;
  }

  &.shaded-bg-tertiary {
    background-color: ${(props) => props.theme.theme.bg.tertiary};
  }

  &.shaded-bg-inset {
    background-color: ${(props) => props.theme.theme.bg.inset};
  }
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`;

const InputGroup = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */

  @media (max-width: 500px) {
    padding: 24px 12px 0 12px;
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const ColumnsWrapper = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) ) ;
  grid-gap: 24px;
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

const HorizontalLine = styled.div`
  border: 0.5px solid ${ props => props.theme.theme.border.primary};
  margin: 24px 0 24px ;
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month #: </strong>
          {payload[0].payload.month}
        </CardText400>

        <CardText400>
          <strong>Monthly payment: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.monthlyPayment.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards interest: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards principal: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.principalPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Loan Balance: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.loanBalance.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MortgageComparisonCalc = () => {

  const [ interestRate, setInterestRate ] = useState(3)
  const [ interestRate15, setInterestRate15 ] = useState(4.6)
  const [ interestRate30, setInterestRate30 ] = useState(5)
  const [ timeLength, setTimeLength ] = useState(20)
  // const [ principal, setPrincipal ] = useState(50000)
  const [ purchasePrice, setPurchasePrice ] = useState(375000)
  const [ downPayment, setDownPayment ] = useState(20)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(12)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)

  const downPaymentValue = purchasePrice * ( downPayment / 100)
  const principal = purchasePrice - downPaymentValue

  const rate = (Math.pow( 1 + ((interestRate/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const rate15 = (Math.pow( 1 + ((interestRate15/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const rate30 = (Math.pow( 1 + ((interestRate30/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const nper15 = annualPaymentPeriods * 15
  const nper30 = annualPaymentPeriods * 30
  const payment = principal * ( ( rate * Math.pow( 1 + rate, nper) ) / ( Math.pow( 1 + rate, nper) - 1) )
  const payment15 = principal * ( ( rate15 * Math.pow( 1 + rate15, nper15) ) / ( Math.pow( 1 + rate15, nper15) - 1) )
  const payment30 = principal * ( ( rate30 * Math.pow( 1 + rate30, nper30) ) / ( Math.pow( 1 + rate30, nper30) - 1) )

  const calcLoanData = () => {
    let loanData = []
    for(let i = 0; i <= nper; i++){

      if(!i){

        const paymentInterest = principal * rate
        const paymentPrincipal = payment - paymentInterest

        loanData.push({
          month: i,
          monthlyPayment: 0,
          interestPortion: 0,
          principalPortion: 0,
          loanBalance: parseFloat(principal),
          totalInterest: 0
        })
      } else {
        if(i < nper){
          const paymentInterest = loanData[i-1].loanBalance * rate
          const paymentPrincipal = parseFloat(payment) - parseFloat(paymentInterest)

          loanData.push({
            month: i,
            monthlyPayment: payment,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: parseFloat(loanData[i-1].loanBalance) - paymentPrincipal,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        } else {
          const paymentInterest = loanData[i-1].loanBalance * rate
          const paymentPrincipal = payment - paymentInterest

          loanData.push({
            month: i,
            monthlyPayment: payment,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: 0,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        }

      }
    }
    return loanData
  }

  const calcLoanData15 = () => {
    let loanData = []
    for(let i = 0; i <= nper; i++){

      if(!i){

        const paymentInterest = principal * rate15
        const paymentPrincipal = payment15 - paymentInterest

        loanData.push({
          month: i,
          monthlyPayment: 0,
          interestPortion: 0,
          principalPortion: 0,
          loanBalance: parseFloat(principal),
          totalInterest: 0
        })
      } else {
        if(i < nper15){
          const paymentInterest = loanData[i-1].loanBalance * rate15
          const paymentPrincipal = parseFloat(payment15) - parseFloat(paymentInterest)

          loanData.push({
            month: i,
            monthlyPayment: payment15,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: parseFloat(loanData[i-1].loanBalance) - paymentPrincipal,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        } else {
          const paymentInterest = loanData[i-1].loanBalance * rate15
          const paymentPrincipal = payment15 - paymentInterest

          loanData.push({
            month: i,
            monthlyPayment: payment15,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: 0,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        }

      }
    }
    return loanData
  }

  const calcLoanData30 = () => {
    let loanData = []
    for(let i = 0; i <= nper30; i++){

      if(!i){

        const paymentInterest = principal * rate30
        const paymentPrincipal = payment30 - paymentInterest

        loanData.push({
          month: i,
          monthlyPayment: 0,
          interestPortion: 0,
          principalPortion: 0,
          loanBalance: parseFloat(principal),
          totalInterest: 0
        })
      } else {
        if(i < nper30){
          const paymentInterest = loanData[i-1].loanBalance * rate30
          const paymentPrincipal = parseFloat(payment30) - parseFloat(paymentInterest)

          loanData.push({
            month: i,
            monthlyPayment: payment30,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: parseFloat(loanData[i-1].loanBalance) - paymentPrincipal,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        } else {
          const paymentInterest = loanData[i-1].loanBalance * rate30
          const paymentPrincipal = payment30 - paymentInterest

          loanData.push({
            month: i,
            monthlyPayment: payment30,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: 0,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        }

      }
    }
    return loanData
  }

  const loanData = calcLoanData()
  const loanData15 = calcLoanData15()
  const loanData30 = calcLoanData30()

  console.log(loanData30)
  console.log(nper30)

  return (
    <Layout>
      <SEO
        title={'Loan Payment Calculator'}
        description={'Visualize and calculate your loan repayment over time'}
      />
      <CustomReactTooltip />

      <CalculatorPanel>
        <CalculatorGrid>
          <form>
            <InputGroup>
              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Home Purchase Price"
                  value={purchasePrice}
                  onChange={event => setPurchasePrice(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the total purchase price of your home.`}
                />
                </InputItem>
              </FlexInput>

              <AllocationGroupGray style={{ width: "250px" }} >
                <Label>
                  Down Payment
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is your down payment as a percentage of your purchase price.`}
                  />
                </Label>
                <AllocationGroupRowTwoCol>
                  <SliderBlack
                    value={downPayment}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    min={0}
                    max={50}
                    marks={[
                      {
                        value: 10,
                        label: "10%"
                      },
                      {
                        value: 20,
                        label: "20%"
                      },
                      {
                        value: 50,
                        label: "50%"
                      }
                    ]}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => setDownPayment(value)}
                  />

                  <ThemedTextarea
                    id="outlined-name"
                    label="Down"
                    value={downPayment}
                    onChange={(event) => setDownPayment(event.target.value)}
                    variant="filled"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                  />
                </AllocationGroupRowTwoCol>
              </AllocationGroupGray>

              <FlexInput style={{ width: "250px" }} >
                <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Down Payment Amount
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the amount of your down payment.`}
                      />
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={downPaymentValue.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
              </FlexInput>

              <FlexInput style={{ width: "250px" }} >
                <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Mortgage Amount
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the starting value of your mortgage. It is the difference between your purchase price and your down payment.`}
                      />
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={principal.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
              </FlexInput>

            </InputGroup>

            <HorizontalLine />

            <ColumnsWrapper>
                <InputGroup>
                  <GroupLabel>15 year mortgage</GroupLabel>
                  <AllocationGroupGray style={{ width: "300px" }}>
                  <Label>
                    Interest Rate
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This is your interest rate on a 15 year mortgage.`}
                    />
                  </Label>
                  <AllocationGroupRowTwoCol>
                    <SliderBlack
                      value={interestRate15}
                      aria-labelledby="discrete-slider-always"
                      step={0.25}
                      min={0}
                      max={10}
                      marks={[
                        {
                          value: 2.5,
                          label: "2.5%"
                        },
                        {
                          value: 5,
                          label: "5%"
                        },
                        {
                          value: 7.5,
                          label: "7.5%"
                        },
                        {
                          value: 10,
                          label: "10%"
                        }
                      ]}
                      valueLabelDisplay="auto"
                      onChange={(event, value) => setInterestRate15(value)}
                    />

                    <ThemedTextarea
                      id="outlined-name"
                      label="Rate"
                      value={interestRate15}
                      onChange={(event) => setInterestRate15(event.target.value)}
                      variant="filled"
                      size="small"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatPercentage,
                      }}
                    />
                  </AllocationGroupRowTwoCol>
                </AllocationGroupGray>

                <FlexInput style={{ width: "250px" }}>
                  <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Monthly Payment
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is your monthly payment on a 15 year mortgage, including principal and interest.`}
                      />
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={payment15.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
                </FlexInput>

                <FlexInput style={{ width: "250px" }}>
                  <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Total Interest
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the total amount of interest you will have paid over the course of your 15 year mortgage.`}
                      />
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={loanData15[nper15].totalInterest.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
                </FlexInput>


              </InputGroup>

              <InputGroup>
                <GroupLabel>30 year mortgage</GroupLabel>
                <AllocationGroupGray style={{ width: "300px" }}>
                <Label>
                  Interest Rate
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is your interest rate on a 30 year mortgage.`}
                  />
                </Label>
                <AllocationGroupRowTwoCol>
                  <SliderBlack
                    value={interestRate30}
                    aria-labelledby="discrete-slider-always"
                    step={0.25}
                    min={0}
                    max={10}
                    marks={[
                      {
                        value: 2.5,
                        label: "2.5%"
                      },
                      {
                        value: 5,
                        label: "5%"
                      },
                      {
                        value: 7.5,
                        label: "7.5%"
                      },
                      {
                        value: 10,
                        label: "10%"
                      }
                    ]}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => setInterestRate30(value)}
                  />

                  <ThemedTextarea
                    id="outlined-name"
                    label="Rate"
                    value={interestRate30}
                    onChange={(event) => setInterestRate30(event.target.value)}
                    variant="filled"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                  />
                </AllocationGroupRowTwoCol>
              </AllocationGroupGray>

                <FlexInput style={{ width: "250px" }}>
                  <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Monthly Payment
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is your monthly payment on a 30 year mortgage, including principal and interest.`}
                      />
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={payment30.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
                </FlexInput>

                <FlexInput style={{ width: "250px" }}>
                  <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      Total Interest
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the total amount of interest you will have paid over the course of your 30 year mortgage.`}
                      />
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={loanData30[nper30].totalInterest.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
                </FlexInput>
              </InputGroup>



            </ColumnsWrapper>

                <FlexInput style={{ width: "250px" }}>
                  <AllocationGroupGray className="no-border shaded-bg-tertiary">
                    <Label>
                      15 Year Interest Savings
                      <MdHelp
                        size="1.2rem"
                        data-tip={`This is the difference in total interest paid for the 15 year and 30 year mortgages.`}
                      />
                    </Label>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={loanData30[nper30].totalInterest.toFixed(0) - loanData15[nper15].totalInterest.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </AllocationGroupGray>
                </FlexInput>

          </form>

            {/* <GraphDesktop>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 50, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={50} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphDesktop>

            <GraphMobile>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 10, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                    mirror
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                    mirror
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={110} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphMobile> */}

        </CalculatorGrid>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>15 year Loan Amortization Schedule</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Month #</span>
                  <span>Payment</span>
                  <span>Interest</span>
                  <span>Principal</span>
                  <span>Loan Balance</span>
                </FinancesTableRow>

                {loanData15.map((dataPoint, index) => {
                  if (!index) {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>{dataPoint.monthlyPayment}</span>
                        <span>{dataPoint.interestPortion}</span>
                        <span>{dataPoint.principalPortion}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  } else {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.monthlyPayment.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.interestPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.principalPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  }
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>30 year Loan Amortization Schedule</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Month #</span>
                  <span>Payment</span>
                  <span>Interest</span>
                  <span>Principal</span>
                  <span>Loan Balance</span>
                </FinancesTableRow>

                {loanData30.map((dataPoint, index) => {
                  if (!index) {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>{dataPoint.monthlyPayment}</span>
                        <span>{dataPoint.interestPortion}</span>
                        <span>{dataPoint.principalPortion}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  } else {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.monthlyPayment.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.interestPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.principalPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  }
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

      </CalculatorPanel>
    </Layout>
  )
}

export default MortgageComparisonCalc
